.documents {
    background: white;
    padding-top: 101px;
    padding-bottom: 144px;
    .documents__list {
        li {
            padding: 30px 10px;
            font-weight: 800;
            font-size: 21px;
            line-height: 27px;
            color: $black;
            border-bottom: 1px solid #DBDFE4;
            position: relative;
            overflow: hidden;
            background: white;
            transition: .4s;
            padding-right: 100px;
            &:first-child {
                border-top: 1px solid #DBDFE4;
            }
            @media (min-width: 768px) {
                padding: 30px 30px;
            }
            &:hover {
                color: $purple;
            }
            cursor: pointer;
            .document__close-btn {
                position: absolute;
                right: 10px;
                top: 22px;
                @media (min-width: 768px) {
                    right: 25px;
                }
                svg {
                    width: 48px;
                    height: 48px;
                    fill: #960FF5;
                    transition: .4s;
                }
            }
            .documents__file  {
                transform: translateY(-100%);
                z-index: -1;
                position: absolute;
                p {
                    font-weight: 600;
                    font-size: 14px;
                    margin-top: 30px;
                    color: $black  !important;
                    a {
                        margin-top: 30px;
                        display: block;
                        background: #0A244A;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: #fff;
                        padding: 11px;
                        width: fit-content;
                        transition: .4s;
                        &:hover {
                            transform: translateX(20px);
                        }
                        .icon {
                            width: 20px;
                            height: 27px;
                            margin-right: 8px;
                        }
                    }
                }
            }
            &._active {
                background: #F7ECFF;
                &:hover {
                    .document__close-btn {
                        svg {
                            fill: black;
                            transform: rotate(120deg);
                        }
                    }    
                }
                .document__close-btn {
                    svg {
                        fill: black;
                        transform: rotate(60deg);
                    }
                }
                .documents__file {
                    position: unset;
                    transform: translateY(0);
                }
            }
        }
    }
}