.equipment {
    padding-top: 121px;
    padding-bottom: 109px;
}
.equipment__container {
    position: relative;
}

.equipment__buttons {
    margin-bottom: 30px;

    @media (min-width: 450px) {
        display: grid;
        grid-template-columns: 213px 213px;
    }
    @media (min-width: 992px) {
        position: absolute;
        top: 0;
        right: 0;    
        margin-bottom: 0;
    }
    .equipment__button {
        position: relative;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        background: #FFFFFF;
        color: $black;
        padding: 10px 20px 10px;
        display: flex;
        z-index: 2;
        align-items: center;
        transition: .4s;
        border: none;
        @media (max-width: 576px) {
            width: 100%;
            justify-content: center
        }
        &._active {
            // background: $purple;
            color: #FFFFFF;
            svg {
                fill: white;
            }
        }
        svg {
            transition: .4s;
            fill: black;
            width: 40px;
            height: 28px;
            margin-right: 9px;
        }
    }
    button:last-of-type:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $purple;
        transform: scaleY(0);
        transform-origin: 50% 0;
        transition-property: transform;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
        @media (min-width: 450px) {
            transform: scaleX(0);
            transform-origin: 0 50%;
        }
    }
    button._active {
        &:before {
            transform: scaleY(1);
            @media (min-width: 450px) {
                transform: scaleX(1);
            }
            
        }
    }
    button:first-of-type:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $purple;
        transform: scaleY(0);
        transform-origin: 50% 100%;
        transition-property: transform;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
        @media (min-width: 450px) {
            transform: scaleX(0);
            transform-origin: 100% 50%;
        }
    }
    button._active {
        &:before {
            transform: scaleY(1);
            @media (min-width: 450px) {
                transform: scaleX(1);
            }            
        }
    }
}

.equipment__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(297px, 1fr));
    gap: 24px;
    li {
        background: #FFFFFF;
        // max-width: 297px;
        padding: 28px;
        padding-top: 8px;
        img {
            width: 228px;
            height: 228px;
            object-fit: contain;
            margin: 0 auto;
            display: block;
        }
        .equipment__title {
            font-weight: 800;
            font-size: 18px;
            line-height: 27px;
            color: $black;
        }
        .equipment__price {
            margin-top: 37px;
            font-weight: 800;
            font-size: 36px;
            color: $purple;
        }
        .equipment__desc {
            margin-top: 16px;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: $black;
        }
        button {
            margin: 0 auto;
            margin-top: 39px;
            max-width: unset;
        }
        button[data-more=true] {
            margin: 0 auto;
            display: block;
            border: none;
            background: none;
            width: fit-content;
            margin-top: 13px;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: $purple;
            text-decoration: underline;
        }
    }
}