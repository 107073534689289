.connection {
    margin-top: 130px;
}
.connection__form {
    margin-top: 44px;
    padding: 28px 10px;
    background: #fff var(--bg-1, 0 0) no-repeat left/100%;
    background-size: cover;
    @media (min-width: 576px) {
        padding: 78px 28px 28px;
    }
    form {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;
        position: relative;
        padding-bottom: 60px;
        @media (min-width: 576px) {
            grid-template-columns: repeat(2, 1fr);
            padding-bottom: 20px;
        }
        @media (min-width: 992px) {
            grid-template-columns: repeat(4, 1fr);
        }
        .form__checkbox {
            position: absolute;
            left: 0;
            bottom: 0;
        }
        button[data-id="connection"] {
            max-width: 100%;
        }
    }
}