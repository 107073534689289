/* Include fonts
   ========================================================================= */

/**
 * Roboto
 */
//@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')

/**
 * Mont
 */
@font-face {
    font-family: "Mont";
    src: url("../fonts/Mont-Bold.woff2") format("woff2"), 
         url("../fonts/Mont-Bold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Mont";
    src: url("../fonts/Mont-Regular.woff2") format("woff2"), 
         url("../fonts/Mont-Regular.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Mont";
    src: url("../fonts/Mont-Regular.woff2") format("woff2"), 
         url("../fonts/Mont-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}