.header {
    z-index: 99;
    background: white;
}
.header__burger {
    @media (min-width: 769px) {
        display: none;        
    }
}
.header__container {
    display: flex;
    align-items: center;
    padding: 19px 0;
    gap: 50px;
    justify-content: space-between;
    @media (min-width: 769px) {
        padding: 24px 0;
    }
}
.header__logo {
    margin-top: auto;
    .logo {
        .icon-logo_mobil {
            width: 134px;
            height: 26px;
        }
        .icon-logo {
            display: none;
        }
        @media (min-width: 769px) {
            .icon-logo {
                display: block;
                width: 140px;
                height: 75px;
            }
            .icon-logo_mobil {
                display: none;
            }
        }
    }
}
.header__menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: 768px) {
        max-width: 100%;
        width: 100%;
        height: 100%;
        max-height: 530px;
        z-index: -1;
        position: fixed;
        top: 0;
        left: 0;
        transition: 0.4s;
        justify-content: flex-end;
        transform: translateX(-100%);
        @media (min-width: 576px) {
            max-width: 375px;
        }
    }
}
.header__menu-container {
    @media (max-width:768px) {
        display: flex;
        flex-direction: column-reverse;
        overflow-y: auto;
        padding: 38px 12px 40px;
        background: #0A244A;
    }    
}
.header__menu.is-active {
    transform: translateX(0);
}
.header__top-menu {
    display: flex;
    gap: 16px;
    flex-direction: column;
    margin-top: 33px;
    @media (min-width: 769px) {
        flex-direction: row;
        margin-top: 0;
        gap: 30px;
    }
    a[data-id="link"]:first-child {
        @media (max-width: 768px) {
            order: 2;
        }
    }
    a[data-id="link"] {
        @media (max-width: 768px) {
            order: 2;
            padding: 12px 0;
            display: flex;
            justify-content: center;
            background-color: #071D3F;
            position: relative;
            color: #FFF;
            svg {
                position: absolute;
                left: 13px;
                fill: #0AC382;
            }
        }
    }
    a[href="#help"] {
        @media (max-width: 768px) {
            order: 3;
        }
    }    
    a[data-id="lc"] {
        order: 1;
        @media (max-width: 768px) {
            justify-content: center;
            position: relative;
            .icon-lc {
                position: absolute;
                left: 13px;
            }
        }
        @media (min-width: 769px) {
            margin-left: auto;
            order: 3;
        }
        
    }
}
a[data-id="link"] {
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 14px;
    text-decoration: none;
    color: $grey;
    transition: .4s;
    &:hover {
        color: $purple;
    }
    svg {
        fill: $purple;
        margin-right: 8px;
        height: 23px;
    }
}
a[data-id="lc"] {
    font-weight: 800;
    font-size: 14px;
    text-decoration: none;
    padding: 12px;
    color: white;
    background: $purple;
    position: relative;
    display: flex;
    align-items: center;
    &:hover:before, :focus:before, :active:before {
        animation-name: hvr-ripple-out;
    }
    .icon {
        width: 28px;
        height: 24px;
        margin-right: 6px;
    }
    &:before {
        content: '';
        position: absolute;
        animation-duration: 1s;
        border: $purple solid 4px;
        top: -12px;
        right: -12px;
        bottom: -12px;
        left: -12px;
        opacity: 0;
    }
}

@keyframes hvr-ripple-out {
    100% {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 1;
    }
}

.header__bottom-menu {
    @media (min-width: 769px) {
        margin-top: 20px;    
    }
    nav {
        ul {
            display: flex;
            gap: 10px;
            width: 100%;
            justify-content: space-between;
            flex-direction: column;
            @media (min-width: 769px) {
                flex-direction: row;
            }
            li {
                a {
                    font-weight: 800;
                    font-size: 15px;
                    line-height: 24px;
                    color: #FFF;
                    transition: .4s;
                    @media (min-width: 769px) {
                        color: $black;
                    }
                    &:hover {
                        color: $purple;
                    }
                }
            }
        }
    }
}
.header__phone {
    a[data-id='link'] svg {
        margin-right: 0;
    }
    @media (min-width: 769px) {
        display: none;
    }
}