.contacts {
    padding-top: 105px;
    padding-bottom: 133px;
    .contacts__content {
        ul {
            background: #FFFFFF;
            display: flex;
            padding: 63px 25px 30px;
            flex-direction: column;
            gap: 30px;
            @media (min-width: 992px) {
                flex-direction: row;
                gap: unset;
            }
            li {
                font-weight: 800;
                font-size: 21px;
                line-height: 24px;
                color: $black;
                padding-left: 90px;
                position: relative;
                padding-right: 40px;
                
                @media (min-width: 992px) {
                    padding-right: 25px;
                    border-right: 1px solid #DBDFE4;
                    min-width: 300px;
                    &:last-child {
                        padding-left: 120px;
                        img {
                            left: 20px;
                        }
                    }
                }
                
                img {
                    position: absolute;
                    object-fit: contain;
                    width: 70px;
                    height: 70px;
                    left: 0;
                    @media (min-width: 992px) {
                        left: 10px;
                    }
                }
                &:last-child {
                    border-right: none;
                }
                p, a  {
                    display: block;
                    font-weight: 600;
                    margin-top: 20px;
                    font-size: 16px;
                    color: $black;
                }

            }
        }
    }
}