.payment {
    padding-top: 153px;
    padding-bottom: 105px;
}
.payment__list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    @media (min-width:992px) {
        grid-template-columns: 1fr 1fr;
    }
}
.payment__item {
    background: linear-gradient(90deg, #A53CF5 0%, #D844FB 100%);
    min-height: 562px;
    position: relative;
    z-index: 2;
    padding: 0;
    .payment__content {
        padding: 46px;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: var(--bg-1, 0 0) no-repeat center/auto 289px, var(--bg-2, 0 0) no-repeat right bottom/auto 306px;    
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        p {
            font-weight: 800;
            font-size: 24px;
            line-height: 32px;
            color: #fff;
            text-align: center;
        }
        a {
            padding: 19px 46px;
            background: #0A244A;
            font-weight: 800;
            font-size: 16px;
            line-height: 24px;
            color: #FFF;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                animation-duration: 1s;
                border: #0A244A solid 4px;
                top: -12px;
                right: -12px;
                bottom: -12px;
                left: -12px;
                opacity: 0;
            }
            &:hover:before, :focus:before, :active:before {
                animation-name: hvr-ripple-out;
            }
        }
    }
}