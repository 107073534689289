.business {
    margin-top: 192px;
    background: linear-gradient(to bottom, #1A4071 40%, #0B2349 40% 60%, #1A4071 60% 10% );
}
.business__container {
    width: 100%;
    height: 100%;
    background: var(--bg-1, 0 0) no-repeat calc(100% - 5px) bottom/320px,var(--bg-2, 0 0) no-repeat left 230px/224px;
    @media (min-width: 768px) {
        background: var(--bg-1, 0 0) no-repeat calc(100% - 5px) bottom,var(--bg-2, 0 0) no-repeat left 230px/224px;    
    }
    .business__content {
        min-height: 612px;
        max-width: 580px;    
        padding-top: 106px;
        padding-bottom: 92px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h2 {
            color: #FFF;
        }
        p {
            color: #FFF;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }
    }
}