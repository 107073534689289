*,
*::before,
*::after
	box-sizing: border-box


a
	text-decoration: none
main
	position: relative
	background: #F5F5F5

.flex
	display: flex
	align-items: flex-start
	justify-content: flex-start

.outer
	padding-left: 12px
	padding-right: 12px


.inner
	max-width: 1260px
	margin-left: auto
	margin-right: auto


body
	font-family: "Mont", sans-serif
	font-style: normal
	font-size: 16px
	font-weight: 400
	color: $black
	min-width: 375px
	overflow: auto
	z-index: 100
	&.is-active
		overflow: hidden