.tariff {
    margin-top: 166px;
}
.tariff__checkbox {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    @media (min-width: 768px) {
        flex-direction: row;
    }
    div:first-of-type {
        text-align: right;
    }
}
.tariff__list {
    position: absolute;
    z-index: -1;
    opacity: 0;
    transform: translateX(-100%);
    // width: 100%;
    margin-top: 78px;
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr;
    justify-content: center;
    gap: 68px;
    transition: all 1s;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        justify-items: stretch
    }
    @media (min-width: 1200px) {
        display: flex;
        flex-direction: column;    
    }
}

.tariff__list._active {
    z-index: 1;
    position: unset;
    opacity: 1;
    transition: all 1s;
    transform: translateX(0);
}
.tariff__item {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 404px;
    background: #FFFFFF;
    @media (min-width: 768px) {
        max-width: unset;
    }
    @media (min-width: 1200px) {
        grid-template-columns: 404px 1fr;
        max-width: unset;
    }
}
.tariff__item-left {
    min-height: 176px;
    padding: 0 28px;
    height: 100%;
    display: flex;
    background: #960FF5;
    position: relative;
    align-items: center;
    img {
        z-index: 1;
        width: 173px;
        height: 148px;
        object-fit: contain;
        position: absolute;
        right: 0;
        bottom: 0;
    }
    .tariff__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 27px;
        z-index: 2;
        color: #FFF;
        text-transform: uppercase;
        p {
            margin: 0;
            display: block;
            margin-top: 17px;
            font-size: 36px;
            color: $green;
            span {
                color: white;
                font-size: 16px;
                text-transform: lowercase;
            }
        }
    }
    .tariff__skew {
        position: absolute;
        bottom: 0;
        left: 72px;
        top: -32px;
        background-color: #a32af9;
        overflow: hidden;
        width: 150px;
        transform: skewX(35deg);
        
        .tariff__skew-text {
            transform: skewX(-35deg);
            position: relative;
            p {
                padding-top: 3px;
                right: -22px;
                left: 0;
                display: block;
                height: 32px;
                // width: 100%;
                border: 1px solid $green;
                background: $green;
                position: absolute;
                margin: 0;
                text-align: center;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #FFF;
            }
        }

    }
}
.tariff__list._red {
    .tariff__item-left {
        background: $red;
        .tariff__skew {
            background: #9a089a;
        }
    }



    
}
.tariff__item-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    @media (min-width: 1200px) {
        flex-direction: row;
        padding-left: 48px;
        padding-right: 27px;
    }
}
.tariff__haract {
    max-width: 440px;
    padding-left: 67px;
    position: relative;
    margin-bottom: 40px;
    @media (min-width: 1200px) {
        margin-bottom: 0;
    }
    .icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 44px;
        height: 37px;
    }
    p {
        margin: 0;
    }
    
    p:first-of-type {
        font-weight: 600;
        font-size: 16px;
        color: $black;
        span {
            font-weight: 800;
        }
    }
    p:last-of-type {
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: #A4A4A4;
        margin-top: 14px;
    }
    p._purple {
        color: $purple;
    }
}